<template>
  <v-card>
    <v-card-title class="mb-5">
      <div class="purple--text font-italic fs-14px">
        *Tất cả các giá trị ghi nhận đã bao gồm VAT (nếu có)
      </div>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="rejectProcess" class="mr-2">
        Không xuất hóa đơn
      </v-btn>
      <v-btn color="success" @click="invoiceCreate" class="mr-2">
        Xuất hóa đơn
      </v-btn>
      <v-btn color="red darken-1" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text class="black--text">
      <v-row dense>
        <v-col cols="3">
          <v-row dense class="align-center">
            <v-col cols="3" class="text-right font-weight-medium">Sàn:</v-col>
            <v-col cols="9"></v-col>

            <v-col cols="3" class="text-right font-weight-medium">Shop:</v-col>
            <v-col cols="9">
              {{ processItem.customer_e_marketplace_shop_name }}
            </v-col>

            <v-col cols="3" class="text-right font-weight-medium">
              Mã đơn:
            </v-col>
            <v-col cols="9">
              {{ processItem.customer_order_id }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row dense class="align-center">
            <v-col cols="9" class="text-right font-weight-medium">
              Doanh thu xuất hóa đơn:
            </v-col>
            <v-col cols="3">
              <input-number
                v-model.number="processItem.revenue"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </v-col>

            <v-col cols="9" class="text-right font-weight-medium">
              Thu nhập khác - Sàn trợ giá:
            </v-col>
            <v-col cols="3">
              <input-number
                v-model.number="processItem.other_revenue"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </v-col>

            <v-col cols="9" class="text-right font-weight-medium">
              Thu nhập khác - Sàn trả chênh lệch phí vận chuyển:
            </v-col>
            <v-col cols="3">
              <input-number
                v-model.number="processItem.other_revenue_delivery"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row dense>
            <v-col cols="8" class="text-right font-weight-medium">
              Doanh thu hàng hóa:
            </v-col>
            <v-col cols="4">
              {{ formatNumber(totalGoodsPrice) }}
            </v-col>

            <v-col cols="8" class="text-right font-weight-medium">
              Chiết khấu thương mại:
            </v-col>
            <v-col cols="4">
              {{ formatNumber(totalDiscount) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-simple-table class="table-padding-2 table-h-36 mb-8">
        <template v-slot:default>
          <tbody>
            <tr class="text-center font-weight-medium">
              <td style="width: 135px">Bacode</td>
              <td>Hàng hóa</td>
              <td>Đặc tả</td>
              <td>Size</td>
              <td style="width: 105px">
                Giá bán lẻ <br />
                trên SSC
              </td>
              <td style="width: 105px">
                Sửa
                <br />
                giá bán lẻ
              </td>
              <td style="width: 105px">
                Giá bán <br />
                trên sàn
              </td>
              <td style="width: 105px">
                SL. <br />
                Xuất kho
              </td>
              <td style="width: 105px">
                SL. <br />
                Xuất hóa đơn
              </td>
              <td style="width: 70px">
                <v-btn small color="primary" @click="addItem">
                  {{ $t("labels.add") }}
                </v-btn>
              </td>
            </tr>
            <tr class="text-center" v-for="(val, key) in items" :key="key">
              <td>
                <v-text-field
                  v-if="!val.id"
                  v-model="val.customer_goods_barcode"
                  class="c-input-small my-1"
                  type="text"
                  dense
                  outlined
                  hide-details
                  single-line
                  @keyup.enter="getItemDetail(val, key)"
                ></v-text-field>
                <span v-else>{{ val.customer_goods_barcode }}</span>
              </td>
              <td>{{ val.name }}</td>
              <td>{{ val.description }}</td>
              <td>{{ val.size }}</td>
              <td>
                {{
                  val.price || val.price == 0 ? formatNumber(val.price) : null
                }}
              </td>
              <td>
                <input-number
                  v-model.number="val.price_update"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
              </td>
              <td>
                <input-number
                  v-model.number="val.price_e_market"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
              </td>
              <td>{{ val.quantity }}</td>
              <td>
                <v-text-field
                  v-model.number="val.total_quantity"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
export default {
  name: "WaitProcess",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    processItem: {},
    items: [],
    isLoading: false,
    isActive: false,
  }),
  computed: {
    totalGoodsPrice() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        total += item.price_update * item.total_quantity;
      }
      return total;
    },
    totalDiscount() {
      return (
        this.totalGoodsPrice +
        this.processItem.other_revenue +
        this.processItem.other_revenue_delivery -
        this.processItem.revenue
      );
    },
    totalGoodsRevenue() {
      return (
        this.processItem.revenue -
        this.processItem.other_revenue -
        this.processItem.other_revenue_delivery
      );
    },
  },
  created() {
    this.processItem = { ...this.item };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    cancel() {
      this.$emit("cancel", true);
    },

    addItem() {
      this.items.push({
        customer_order_id: this.processItem.customer_order_id,
        price: 0,
        price_update: 0,
        price_e_market: 0,
        quantity: 0,
        total_quantity: 1,
      });
    },

    async getItemDetail(val, key) {
      if (!val.customer_goods_barcode) {
        return false;
      }
      const duplicates = [...this.items].filter(
        (i, k) =>
          i.customer_goods_barcode == val.customer_goods_barcode && k != key
      );

      if (duplicates.length > 0) {
        const duplicateCodes = [...duplicates].map(
          (i) => i.customer_goods_barcode
        );
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateCodes.join("; "),
          })
        );
        return false;
      }
      try {
        const { data } = await httpClient.post("/get-goods-by-barcode", {
          customer_goods_barcode: val.customer_goods_barcode,
        });
        const items = [...this.items];
        items[key] = { ...val, ...data };
        this.items = [...items];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async getItems() {
      const { data } = await httpClient.post("/invoice-get-order-item", {
        customer_order_ids: [this.processItem.customer_order_id],
        id_customer_e_marketplace_shop:
          this.processItem.id_customer_e_marketplace_shop,
        type_create_export: 1,
        type_product_order: 1,
      });
      this.items = [...data].map((d) => ({
        ...d,
        price_update: 0,
        price_e_market: 0,
        quantity: +d.total_quantity,
        total_quantity: +d.total_quantity,
      }));
      // this.calItemRevenue();
    },

    calItemRevenue: debounce(function () {
      httpClient
        .post("/invoice-get-revenue-item", {
          orders: [
            {
              ...this.processItem,
            },
          ],
          items: [...this.items].filter((item) => item.total_quantity > 0),
        })
        .then(({ data }) => {
          console.log(data);
        });
    }, 500),

    async rejectProcess() {
      if (!confirm("Bạn có chắc chắn muốn KHÔNG xuất hóa đơn?")) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-reject-wait-process", {
          id: this.processItem.id,
        });

        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("success");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async invoiceCreate() {
      if (!confirm("Bạn có đã kiểm tra kỹ dữ liệu chưa?")) {
        return false;
      }

      /* if (this.processItem.revenue == 0) {
        this.$vToastify.warning("Không xuất hóa đơn với doanh thu = 0");
        return false;
      } */

      const errItems = [...this.items].filter(
        (item) =>
          item.price_e_market === "" ||
          item.price_update === "" ||
          item.price_e_market > item.price_update
      );
      if (errItems.length > 0) {
        this.$vToastify.warning("Giá hàng hóa chưa đúng");
        return false;
      }

      if (this.totalGoodsRevenue > 0 && this.items.length === 0) {
        this.$vToastify.warning("Không có hàng hóa nào được ghi nhận");
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-process-create", {
          order: {
            ...this.processItem,
            goods_revenue: this.totalGoodsRevenue,
          },
          items: [...this.items].filter((item) => item.total_quantity > 0),
        });

        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("success");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>
